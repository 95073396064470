import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

import { PricePoinProps, PriceProps } from '@/types/product';

import { currencyFormatter } from '@/lib/currencyFormatter';

interface PriceProductProps {
  cvreview: PriceProps[];
}

const PriceProduct = ({ cvreview }: PriceProductProps) => {
  const router = useRouter();
  return (
    <section className="py-12" id="price">
      <div className="flex flex-col">
        <p className="text-xl md:text-2xl lg:text-3xl font-semibold mx-auto mb-10">
          Mulai Investasi Dengan Kerjago
        </p>
        <div className="flex flex-wrap justify-center gap-6">
          {cvreview.map((price: PriceProps) => (
            <div
              className={clsx(
                'p-8 transition-colors duration-200 transform rounded-md border-2 border-kerjago-primary w-96 flex flex-col',
                price.background ? 'bg-kerjago-primary text-white' : 'bg-white',
              )}
              key={price.title}
            >
              {price.popular && (
                <div className="mb-3">
                  <span className="py-1 px-2 bg-blue-200 text-blue-800 rounded-lg">
                    Popular
                  </span>
                </div>
              )}
              <div className="px-3 py-2 mb-3 bg-gray-200 rounded text-kerjago-primary font-semibold w-max">
                {price.title}
              </div>
              <p className={clsx('mb-5')}>{price.desc}</p>
              <div className="divider" />
              {price.discValue && (
                <p className="line-through  text-[#F4323C]">
                  {currencyFormatter.format(price.price)}
                </p>
              )}
              <p className="text-4xl font-semibold">
                {currencyFormatter.format(price.price - (price.discValue || 0))}
              </p>
              <div>
                {price.notes ? (
                  <div className="text-gray-500">{price.notes}</div>
                ) : (
                  <></>
                )}
              </div>
              <div className="divider" />
              <div className="mt-8 space-y-4 mb-8">
                {price.poin.map((adv: PricePoinProps) => (
                  <div
                    className="flex items-center justify-start"
                    key={adv.data}
                  >
                    {adv.check ? (
                      <FaCheckCircle
                        className={clsx(
                          'text-md w-1/12',
                          price.background
                            ? 'text-white'
                            : 'text-kerjago-primary',
                        )}
                      />
                    ) : (
                      <FaTimesCircle className="text-md w-1/12 text-red-500" />
                    )}

                    <span className="mx-4 w-11/12">{adv.data}</span>
                  </div>
                ))}
              </div>
              <div className="mt-auto">
                <button
                  onClick={() => router.push(price.url)}
                  type="button"
                  className={clsx(
                    'w-full px-4 py-2 transition-colors duration-200 transform rounded',
                    'tracking-wide capitalize font-medium',
                    price.background
                      ? 'bg-kerjago-ternary text-black'
                      : 'bg-kerjago-secondary hover:bg-kerjago-primary text-white',
                  )}
                >
                  Coba Sekarang!
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PriceProduct;
