import { HTMLProps } from 'react';
import Slider, { Settings } from 'react-slick';

import { TestimoniProps } from '@/types/product';

import TestimoniCard from '@/components/testimoniCard';

interface TestimoniProductProps {
  dataTestimoni: TestimoniProps[];
}

function SampleNextArrow(props: HTMLProps<HTMLButtonElement>) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props: HTMLProps<HTMLButtonElement>) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}

const settings: Settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1536,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  arrows: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

const TestimoniProduct = ({ dataTestimoni }: TestimoniProductProps) => (
  <section className="py-12">
    <div className="flex flex-col">
      <p className="text-xl md:text-2xl lg:text-3xl font-semibold mx-auto mb-10">
        Apa Kata Mereka?
      </p>
      {/* grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 */}
      <Slider {...settings}>
        {dataTestimoni.map((testimoni: TestimoniProps) => (
          <TestimoniCard data={testimoni} key={testimoni.title} />
        ))}
      </Slider>
    </div>
  </section>
);

export default TestimoniProduct;
