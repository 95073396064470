import { BenefitProps, PriceProps } from '@/types/product';

export const MockInterviewBenefits: BenefitProps[] = [
  {
    title: 'Based on Role Interview',
    desc: 'Kamu bebas memilih akan di-interview sebagai posisi apa (contoh: software engineer). Talent Kerjago akan menyesuaikan sesuai role dan jenis interview yang kamu pilih (HR atau User).',
    image: 'free.png',
  },
  {
    title: 'Live Feedback',
    desc: 'Kamu akan mendapatkan feedback langsung dari Talent Kerjago selama dan setelah Mock Interview',
    image: 'feedback.png',
  },
  {
    title: '60 Mins Interview Session',
    desc: 'Mock Interview selama 60 menit termasuk diskusi terkait feedback, proses rekrutmen, rekomendasi pelatihan, dll.',
    image: '60-minutes.png',
  },
];

export const MockInterviewHR: PriceProps = {
  background: false,
  title: 'Mock HR Interview',
  popular: false,
  desc: 'Untuk kamu yang butuh persiapan menghadapi interview bersama HR atau human resources.',
  price: 100_000,
  discValue: 25_000,
  poin: [
    {
      check: true,
      data: 'Memilih Talent Kerjago sesuai role yang ingin dilamar',
    },
    {
      check: true,
      data: 'Durasi Interview 60 menit',
    },
    {
      check: true,
      data: 'Feedback langsung setelah selesai interview',
    },
    {
      check: true,
      data: 'Dapat memilih User Interview & HR Interview',
    },
  ],
  url: '/redirect/https%3A%2F%2Fforms.gle%2FYvb9BtmTkh3GwTD27?title=Google%20Forms',
};

export const MockInterviewUser: PriceProps = {
  background: false,
  title: 'Mock User Interview',
  popular: false,
  desc: 'Untuk kamu yang butuh persiapan menghadapi interview bersama user atau hiring manager.',
  price: 100_000,
  discValue: 25_000,
  poin: [
    {
      check: true,
      data: 'Memilih Talent Kerjago sesuai role yang ingin dilamar',
    },
    {
      check: true,
      data: 'Durasi Interview 60 menit',
    },
    {
      check: true,
      data: 'Feedback langsung setelah selesai interview',
    },
    {
      check: true,
      data: 'Dapat memilih User Interview & HR Interview',
    },
  ],
  url: '/redirect/https%3A%2F%2Fforms.gle%2FYvb9BtmTkh3GwTD27?title=Google%20Forms',
}