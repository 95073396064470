import { TestimoniProps } from "@/types/product";

export const testimonial: TestimoniProps[] = [
  {
    title: 'On Target!',
    desc: 'Saran yang diberikan menghasilkan pengetahuan baru terkait CV seperti apa yang dicari oleh tim Hiring yang menyeleksi anda.',
    name: 'Azhar Baihaqi Nugraha',
    job: 'Ackcio - Software Engineer Intern',
    image: 'azhar.jpg',
  },
  {
    title: 'Sangat Membantu',
    desc: 'Kerjago membantu saya dalam memilah informasi yang sebaiknya dicantumkan di CV agar tetap on point dan informatif, sehingga menarik minat perusahaan.',
    name: 'Faishal Januarahman',
    job: 'Investree - Frontend Engineer Intern',
    image: 'faishal.jpg',
  },
  {
    title: 'Informatif & Menarik',
    desc: 'Kerjago membantu menyiapkan CV dengan baik sehingga kesempatan lolos seleksi semakin tinggi.',
    name: 'Artisa Bunga Syahputri',
    job: 'Telkom Indonesia - Data Science Intern',
    image: 'artisa.jpg',
  },
  {
    title: 'Lebih Siap Bersaing!',
    desc: 'Kerjago sangat membantuku dalam memperbaiki cv sehingga menjadi lebih siap bersaing dengan intern hunter lainnya',
    name: 'Zendy Bramantya Alfareza',
    job: 'Telkom Indonesia - Software Engineer Intern',
    image: 'zendy.jpg',
  },
  {
    title: 'Komprehensif & Terperinci!',
    desc: 'Hasil review & koreksi dari Kerjago sangat lengkap, rinci, & jelas. Dijelasin dibagian mana saja yang kurang jelas atau tidak perlu di cantumkan secara detail. Kerjago juga memberi masukan jika ada yang informasi yang kurang baik di CV maupun portofolio',
    name: 'Arya Saddam Lee Garibaldi',
    job: 'Telkom Indonesia - Digital Marketing Intern',
    image: 'arya.jpg',
  },
  {
    title: 'Meningkatkan Kompetitivitas CV',
    desc: 'Kerjago sangat membantuku dalam memperbaiki cv sehingga menjadi lebih siap bersaing dengan intern hunter lainnya',
    name: 'Tiara Abriliana Agatha',
    job: 'Mahasiswa Ekonomi Universitas Islam Indonesia',
    image: 'tiara.jpg',
  },
  {
    title: 'Kerenn Bangett!',
    desc: 'Maa Syaa Allah kerenn bangett, sangat membantu. Terima Kasih banyak kak^^',
    name: 'Mulia Sari',
    job: 'Mahasiswa Ekonomi Universitas Brawijaya',
    image: '',
  },
  {
    title: 'Lengkap dan Penting',
    desc: 'Review CV Kerjago sangat membantu saya dalam menyusun CV yang lengkap. Review yang saya dapatkan dari Kerjago mengenai personal branding dan ketelitian terhadap informasi penting dalam CV saya.',
    name: 'Kurnia Sari Purwati',
    job: 'Lemigas - Jr. Geologist',
    image: '',
  },
];