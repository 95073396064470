import { BenefitProps } from '@/types/product';

import BlurImage from '../blurImage';

type BenefitCardProps = {
  data: BenefitProps;
};

const BenefitCard = ({ data, ...props }: BenefitCardProps) => (
  <div {...props} className="block p-5 text-center justify-center">
    <BlurImage
      src={`/benefits/${data.image}`}
      alt="benefit-images"
      className="object-contain justify-center"
      height={75}
      width={75}
    />
    <h5 className="mt-5 text-xl font-bold text-gray-900">{data.title}</h5>

    <p className="mt-2 text-gray-700">{data.desc}</p>
  </div>
);

export default BenefitCard;
