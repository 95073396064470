import BlurImage from '@/components/blurImage';

const PartnerProduct = () => (
  <section className="py-12">
    <div className="flex flex-col">
      <p className="text-xl md:text-2xl lg:text-3xl font-semibold mx-auto mb-10">
        Our Company Talent
      </p>
      <div className="gap-6 items-center justify-center grid md:flex flex-wrap grid-cols-3">
        <BlurImage
          alt="logo-tokopedia"
          src="/mitra/logo-tokopedia.svg"
          height={100}
          width={150}
        />
        <BlurImage
          alt="logo-tiketdotcom"
          src="/mitra/logo-tiketdotcom.svg"
          height={100}
          width={150}
        />
        <BlurImage
          alt="logo-xendit"
          src="/mitra/logo-xendit.svg"
          height={100}
          width={150}
        />
        <BlurImage
          alt="logo-telkom"
          src="/mitra/logo-telkomindonesia.svg"
          height={100}
          width={150}
        />
        <BlurImage
          alt="logo"
          src="/mitra/logo-generasicakap.svg"
          height={100}
          width={150}
        />
        <BlurImage
          alt="logo-bangkit"
          src="/mitra/logo-bangkit.svg"
          height={100}
          width={150}
        />
        <div className="invisible" />
        <BlurImage
          alt="logo-oy"
          className="object-contain"
          src="/mitra/logo-oy.png"
          height={100}
          width={150}
        />
        <BlurImage
          alt="logo-xl"
          className="object-contain"
          src="/mitra/logo-xl.png"
          height={100}
          width={150}
        />
      </div>
    </div>
  </section>
);

export default PartnerProduct;
