interface HeroTitleProps {
  title: string;
}
const HeroTitle = ({ title }: HeroTitleProps) => (
  <h2 className="mb-5 text-3xl font-semibold leading-tight tracking-tight md:text-4xl">
    {title}
  </h2>
);

export default HeroTitle;
