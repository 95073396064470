import { BenefitProps, PriceProps, ProductPriceI } from '@/types/product';

export const ProductPriceCVReviewPremium: ProductPriceI = {
  item: [
    {
      name: 'CV Review Premium',
      idrPrice: 75000,
      category: 'CV Review',
      url: 'https://kerjago.id/cv-review',
    },
  ],
  fees: [
    {
      name: 'Discount',
      idrPrice: -35000,
    },
    {
      name: 'Admin',
      idrPrice: 2000,
    },
  ],
};

export const ProductPriceCVReviewReguler: ProductPriceI = {
  item: [
    {
      name: 'CV Review Reguler',
      idrPrice: 40_000,
      category: 'CV Review',
      url: 'https://kerjago.id/cv-review',
    },
  ],
  fees: [
    {
      name: 'Discount',
      idrPrice: -25_000,
    },
    {
      name: 'Admin',
      idrPrice: 2000,
    },
  ],
};

export const calculatePositiveAmount = (p: ProductPriceI) =>
  [...p.item, ...p.fees]
    .map((v) => v.idrPrice)
    .reduce((a, b) => {
      return b > 0 ? a + b : a;
    }, 0);

export const calculateNegativeAmount = (p: ProductPriceI) =>
  [...p.item, ...p.fees]
    .map((v) => v.idrPrice)
    .reduce((a, b) => {
      return b < 0 ? a + b : a;
    }, 0);

export const calculateAmount = (p: ProductPriceI) =>
  calculatePositiveAmount(p) + calculateNegativeAmount(p);

export const CvReviewBenefits: BenefitProps[] = [
  {
    title: 'Bebas Konsultasi',
    desc: 'Kami menyediakan layanan konsultasi setelah me-review CV kamu, sehingga diharapkan dapat menjawab rasa penasaran terhadap hasil review.',
    image: 'communication.png',
  },
  {
    title: 'Based on Role Review',
    desc: 'Kamu bebas memilih akan di-review sebagai posisi apa (contoh: software engineer). Talent Kerjago akan menyesuaikan review sesuai role yang kamu pilih.',
    image: 'free.png',
  },
  {
    title: 'Hasil Cepat & Insightful',
    desc: 'Kamu dapat menerima hasil review dalam waktu 2 x 24 jam dengan rekomendasi yang membantu perbaikan CV-mu.',
    image: 'clock.png',
  },
];

export const CVReviewFree: PriceProps = {
  background: false,
  title: 'CV Review',
  popular: false,
  desc: 'Untuk kamu yang pengen tahu bagian CV yang bisa dimaksimalkan.',
  price: 0,
  notes: 'untuk pemesanan pertama',
  poin: [
    {
      check: true,
      data: 'Feedback CV dari reviewer',
    },
    {
      check: false,
      data: 'Di review oleh reviewer dari perusahaan ternama',
    },
    {
      check: false,
      data: 'Hasil review CV yang detail dan insightful',
    },
    {
      check: false,
      data: 'Gratis konsultasi dengan CV Reviewer selama 30 menit',
    },
    {
      check: false,
      data: 'Hasil review di terima dalam 2x24 jam',
    },
  ],
  url: '/order/cv-review-free',
};

export const CVReviewReguler: PriceProps = {
  background: false,
  title: 'CV Review',
  popular: false,
  desc: 'Untuk kamu yang butuh feedback tertulis dan konsultasi mengenai CV lewat online meeting.',
  price: calculatePositiveAmount(ProductPriceCVReviewReguler),
  discValue: -calculateNegativeAmount(ProductPriceCVReviewReguler),
  poin: [
    {
      check: true,
      data: 'Feedback CV yang komprehensif',
    },
    {
      check: true,
      data: 'Di review oleh reviewer dari perusahaan ternama',
    },
    {
      check: true,
      data: 'Hasil review CV yang detail dan insightful',
    },
    {
      check: false,
      data: 'Gratis konsultasi dengan CV Reviewer selama 30 menit',
    },
    {
      check: true,
      data: 'Hasil review di terima dalam 2x24 jam',
    },
  ],
  url: '/order/cv-review-reguler',
};

export const CVReviewPremium: PriceProps = {
  background: false,
  title: 'CV Review Premium',
  popular: true,
  desc: 'Untuk kamu yang butuh feedback dari CV kamu saat mendaftar role tertentu dan ada sesi feedback secara live meet.',
  price: calculatePositiveAmount(ProductPriceCVReviewPremium),
  discValue: -calculateNegativeAmount(ProductPriceCVReviewPremium),
  poin: [
    {
      check: true,
      data: 'Feedback CV yang komprehensif',
    },
    {
      check: true,
      data: 'Di review oleh reviewer dari perusahaan ternama',
    },
    {
      check: true,
      data: 'Hasil review CV yang detail dan insightful',
    },
    {
      check: true,
      data: 'Gratis konsultasi dengan CV Reviewer selama 30 menit',
    },
    {
      check: true,
      data: 'Hasil review di terima dalam 2x24 jam',
    },
  ],
  url: '/order/cv-review-premium',
};
