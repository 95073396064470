import { PriceProps } from '@/types/product';
import { CVReviewPremium, CVReviewReguler, CvReviewBenefits } from './cv-review';
import { MockInterviewHR, MockInterviewUser, MockInterviewBenefits } from './mock-interview';

const DataProduct = [
  {
    name: 'CV Review',
    description:
      'Curriculum vitae kamu langsung diulas dan diberi masukan oleh orang yang berpengalaman di dunia HR.',
    picture: '/background/cv-riview.png',
    descriptionProduct:
      'Haloo, mingo mau kasih kabar nih kalau fitur CV Review udah bisa temen - temen pakai melalui link dibawah ya. Selamat mencoba!',
    url: '/cv-review',
  },
  {
    name: 'Mock Interview',
    description:
      'Simulasi wawancara bersama rekruter supaya kamu lebih pede saat melamar pekerjaan.',
    picture: '/background/mock-interview.png',
    descriptionProduct:
      'Mock interview dibuat untuk mempersiapkan diri dalam menghadapi proses interview dari mulai human resources interview sampai user interview, jangan lupa rasakan manfaatnya.',
    url: '/mock-interview',
  },
  // {
  //   name: 'Test Minat & Bakat',
  //   description:
  //     'Kenalan dengan diri sendiri melalui minat dan bakat tersembunyi yang ada di dalam diri kamu.',
  //   picture: '/background/tes-minat-bakat.png',
  //   descriptionProduct: 'Semangat menunggu fitur Mock Interview.',
  //   url: '/produk',
  // },
];


export const CvReviewPrice: PriceProps[] = [
  CVReviewReguler,
  CVReviewPremium
];

export const MockInterviewPrice: PriceProps[] = [
  MockInterviewHR,
  MockInterviewUser
  
];

export const MainPrice: PriceProps[] = [
  MockInterviewHR,
  CVReviewPremium,
  CVReviewReguler
];

export default DataProduct;