import { BsTriangleFill } from 'react-icons/bs';

import { TestimoniProps } from '@/types/product';

import BlurImage from '../blurImage';
import getInitial from '@/utils/getInitial';

interface TestimoniCardProps {
  data: TestimoniProps;
}

const TestimoniCard = ({ data }: TestimoniCardProps) => (
  <div className="flex flex-col items-center mx-3">
    <div className="p-8 rounded-xl bg-white text-center flex flex-col min-h-[250px] md:min-h-[200px]">
      <p className="text-xl font-bold mb-5">{data.title}</p>
      <p className="text-base my-auto">{data.desc}</p>
    </div>
    <BsTriangleFill className="rotate-180 text-white -mt-1 text-xl mb-2" />
    <div className="avatar flex flex-col items-center mb-2">
      <div className="w-16 rounded-full bg-gray-500">
        {data.image ? (
          <BlurImage
            src={`/testimoni/${data.image}`}
            width={64}
            height={64}
            alt="profile"
          />
        ) : (
          <p className="uppercase w-full h-full font-bold text-white flex flex-row justify-center items-center">{getInitial(data.name)}</p>
        )}
      </div>
    </div>
    <div className="text-center gap-2">
      <p className="font-semibold">{data.name}</p>
      <p>{data.job}</p>
    </div>
  </div>
);

export default TestimoniCard;
