export default function getInitial(name: string, maxInitial: number = 2): string | null {
  // Ensure the input is a non-empty string
  if (typeof name === 'string' && name.trim().length > 0) {
    // Split the name into words using space as the delimiter
    const words = name.trim().split(' ');

    // Initialize an empty string to store the initials
    let initials = '';

    // Loop through each word and append the first character (initial) to the result
    for (const word of words) {
      initials += word.charAt(0).toUpperCase();
      if (initials.length >= maxInitial) {
        break
      }
    }

    return initials;
  } else {
    return null; // Return null for invalid inputs
  }
}
