import { ReactNode } from 'react';

import HeroSubtitle from './HeroSubtitle';
import HeroTitle from './HeroTitle';

interface HeroProps {
  title: string;
  subtitle: string;
  children: ReactNode;
}

const Hero = ({ title, subtitle, children }: HeroProps) => (
  <section className="py-24 text-white">
    <div className="w-full">
      <HeroSubtitle title={subtitle} />
      <HeroTitle title={title} />
      {children}
    </div>
  </section>
);

export default Hero;
