export const MetaHome = {
  header: 'Kerjago',
  title:
    'Kerjago - Boost Karir kamu dengan CV Review Free & Mock Interview Berkualitas.',
  url: 'https://kerjago.com/',
  description:
    'Siapkan karirmu bersama talent profesional di Kerjago dengan jasa Review CV dan Simulasi Interview yang komprehensif! Bangun karirmu sekarang!',
  thumbnail: '/thumb_hd.png',
  keywords: [
    'Kerjago',
    'Kerja Go',
    'Ulas',
    'CV',
    'Review',
    'Interview',
    'Mock',
    'Simulasi',
  ],
};

export const MetaMockInterview = {
  ...MetaHome,
  title: 'Coba Dulu Mock Interview!',
  description:
    'Simulasi wawancara bersama rekruter supaya kamu bisa lebih pede saat melamar pekerjaan',
};

export const MetaCVReview = {
  ...MetaHome,
  title: 'Kini Hadir CV Review!',
  description:
    'Curriculum vitae kamu langsung diulas dan diberi masukan oleh orang yang berpengalaman di dunia HR.',
};

export const MetaLoker = {
  ...MetaHome,
  title: 'Cari Loker Disini!',
  description:
    'Temukan pekerjaan impian Anda di berbagai industri. Segera cari lowongan pekerjaan terbaru dan kirim lamaran Anda!',
};

export const MetaLokerID = {
  ...MetaHome,
  title: 'Loker - ',
};

